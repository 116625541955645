@import url(https://fonts.googleapis.com/css?family=Inter);
/*Estilo para a página login*/
body {
  background-image: url('/media/bg/bg-4.webp');
}

.mt_container {
  padding: 5% 2rem 1rem 2rem;
  margin: 0 auto 2rem auto;
  overflow: hidden;
  width: 50%;
}

.mt_container2 {
  padding: 5% 2rem 1rem 2rem;
  margin: 0 auto 2rem auto;
  overflow: hidden;
  width: 70%;
}

.container_login {
  width: 100%;
  margin: 5%;
}
.container_login input:focus {
  background: #f7f8fa2b;
  color: #fff;
}

/*Login Topo*/
.mt_img_logo {
  width: 180px;
}

/*Conteúdo login*/
.mt_signin {
  display: flex;
  justify-content: center;
}

.mt_message {
  font-size: 1.1rem;
  font-weight: 500;
  color: #fce188;
}

.mt_form input {
  height: 46px;
  border-radius: 46px;
  border: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 1.5rem;
  background: #f7f8fa2b;
  color: #fff;
}
.mt_form input:active {
  height: 46px;
  border-radius: 46px;
  border: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 1.5rem;
  background: #f7f8fa2b;
  color: #fff;
}
.mt_form input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
  opacity: 1;
}
.mt_form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.mt_form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

/*Estilo principal de toda a aplicação*/
html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 13px;
  font-weight: 300;
  font-family: Poppins, Helvetica, sans-serif; /*Fonte padrão utilizada para todo o frontend*/
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 625px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}

label.important:after {
  content: ' *';
  color: red;
  font-weight: bold;
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1100px !important;
  }
}

.homeLogin {
  min-height: 625px;
}

.errorMessages2 {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  padding: 2px;
  display: flex;
  justify-content: center;
  font-size: 9px;
}
.container-fluido {
  min-height: 625px;
}

.container-fluido {
  background-image: url('/media/bg/bg-4.webp');
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background-size: cover;
}
@media (max-width: 1366px) {
  .container-fluido {
    background-image: url('/media/bg/bg-4.webp');
    position: static;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    background-size: cover;
  }
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card-header {
  padding: 2rem 2.25rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #ebedf3;
}
.card-header small {
  font-size: 70%;
  font-weight: 400;
  color: #cecece;
}

.card-label {
  color: #000;
}
.card-body {
  padding: 2rem 2.25rem;
  color: #3f4254;
}
.card-title {
  color: #000;
}
.btn.btn-success {
  color: #ffffff;
  background-color: #1bc5bd;
  border-color: #1bc5bd;
}
.btn.btn-danger {
  color: #ffffff;
  background-color: #ee2d41;
  border-color: #ee2d41;
}
.btn.btn-primary {
  color: #ffffff;
  background-color: #3699ff;
  border-color: #3699ff;
}
.badge-danger {
  color: #fff;
  background-color: #ee2d41;
  padding: 5px 10px;
}
.badge-success {
  color: #fff;
  background-color: #1bc5bd;
  padding: 5px 15px;
}
.modal-header {
  padding: 1.5rem 1.75rem;
  border-bottom: 1px solid #ebedf3;
}
.modal-title {
  font-weight: 500;
  font-size: 1.3rem;
  color: #181c32;
}
.modal-content {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgb(0 0 0 / 0%);
  border-radius: 0.5rem;
}
.modal-body {
  padding: 1.75rem;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1bc5bd;
  background-color: #1bc5bd;
}

.breadcrumbs {
  background: #fff;
}
.breadcrumbs a {
  color: #000;
  margin: 0 10px;
}
.breadcrumbs a:hover {
  color: #601617;
  transition: 0.5s;
}
.alert-primary {
  background: #e1f0ff;
  color: #3699ff;
  border: 0;
}
.custom-switch {
  padding-left: 0rem;
  display: flex;
  justify-content: center;
}
.alert-success {
  color: #1bc5bd;
  background-color: #defffd;
  border-color: #defffd;
}

/*Inicio Switch*/

.switch {
  position: relative;
  display: inline-block;
  height: 1rem;
  width: 1.75rem;
  pointer-events: all;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  border: #adb5bd solid 1px;
  transition: 0.1s;
}

.slider:before {
  position: absolute;
  content: '';
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  top: 1px;
  left: 0.8px;
  /*bottom: 4px;*/
  background-color: white;
  background-color: #adb5bd;
  transition: 0.1s;
}

input:checked + .slider {
  background-color: #1bc5bd;
  border: #1bc5bd solid 1px;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(10px);
  background-color: white;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/*Fim Switch*/

.MUIDataTable-responsiveBase {
  overflow: visible !important;
}

a {
  color: rgba(255, 255, 255, 0.6);
}
a:hover {
  color: #fff;
  text-decoration: none;
  transition: 0.5s;
}

/*Título*/
.mt_login_title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  padding-top: 20px;
}

/*Formulário*/

.mt_login_option {
  padding-left: 7px;
  padding-right: 7px;
  margin: 15px auto;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1rem;
}

.mt_login_forgot {
  padding-top: 7px;
}

/*Alteração de cor do checkbox de login*/
.MuiCheckbox-root {
  color: rgb(255 255 255 / 54%) !important;
}

.mt_button {
  height: 46px;
  display: inline-block;
  text-align: center;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 0.8rem;
  border-radius: 60px;
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.mt_button:hover {
  border: 1px solid rgb(255, 255, 255);
  transition: 0.5s;
}

.mt_conta {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-10-23 7:49:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-hor-bottom
 * ----------------------------------------
 */

.flip-in-hor-bottom {
  animation: flip-in-hor-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes flip-in-hor-bottom {
  0% {
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

/*Responsividade abaixo desta linha*/
@media (max-width: 768px) {
  .mt_container {
    padding: 10% 2rem 1rem 2rem;
    width: 90% !important;
  }
  .mt_option {
    padding-top: 8px;
    display: flex;
    justify-content: center;
  }
  .mt_signin {
    margin: 0;
  }
}

/*Olho password*/

.input_password {
  width: 100%;
  position: relative;
  display: -webkit-inline-box;
}

.fa-eye,
.fa-eye-slash {
  position: absolute;
  top: 35px;
  right: -30px;
  cursor: pointer;
}

/*Container*/

.kt-login__title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
}

.kt-login__desc {
  margin-top: 1.5rem;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}

/*Formulário*/



.kt-login__btn-primary {
  height: 46px;
  display: inline-block;
  text-align: center;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 0.8rem;
  border-radius: 60px;
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.mt_button_second {
  height: 46px;
  display: inline-block;
  text-align: center;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 0.8rem;
  border-radius: 60px;
  background: transparent;
  color: rgba(255, 255, 255, 0.103);
  border: 1px solid rgba(255, 255, 255, 0.103);
}

.kt-login__actions {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2rem;
}

/*Responsividade abaixo desta linha*/
@media (max-width: 768px) {
  .mt_container {
    padding: 10% 2rem 1rem 2rem;
    width: 90% !important;
  }
  .mt_option {
    padding-top: 8px;
    display: flex;
    justify-content: center;
  }
  .mt_signin {
    margin: 0;
  }
}

.loader  {
  animation: rotate 1s infinite;  
  height: 50px;
  width: 50px;
  position: fixed;
  z-index: 999;
top: 50%;
left: 50%;
/* bring your own prefixes */
transform: translate(-50%, -50%);
}

.loader:before,
.loader:after {   
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;  
  width: 20px;
}
.loader:before {
  animation: ball1 1s infinite;  
  background-color: #af3536;
  box-shadow: 30px 0 0 #af3536;
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1s infinite; 
  background-color: #af3536;
  box-shadow: 30px 0 0 #af3536;
}

@keyframes rotate {
  0% { 
    -webkit-transform: rotate(0deg) scale(0.8); 
    -moz-transform: rotate(0deg) scale(0.8);
  }
  50% { 
    -webkit-transform: rotate(360deg) scale(1.2); 
    -moz-transform: rotate(360deg) scale(1.2);
  }
  100% { 
    -webkit-transform: rotate(720deg) scale(0.8); 
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #af3536;
  }
  50% {
    box-shadow: 0 0 0 #af3536;
    margin-bottom: 0;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #af3536;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #af3536;
  }
  50% {
    box-shadow: 0 0 0 #af3536;
    margin-top: -20px;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #af3536;
    margin-top: 0;
  }
}

.mt_register_desc {
  margin-bottom: 1rem;
}

.mt_register_signup {
  display: block;
}

.mt_form_register {
  padding-right: 4%;
  padding-left: 4%;
}

.mt_register_head {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
}

.mt_register_title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
}

.mt_register_head .mt_register_desc {
  margin-top: 1.5rem;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}

#mt_form_register input {
  height: 46px;
  border-radius: 46px;
  border: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background: #f7f8fa2b;
  color: #fff;
}
#mt_form_register input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
  opacity: 1;
}
#mt_form_register input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
#mt_form_register input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.mt_register_actions {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2rem;
}

.mt_button_second {
  height: 46px;
  display: inline-block;
  text-align: center;
  padding-left: 4rem;
  padding-right: 4rem;
  /* margin-top: 0.8rem; */
  border-radius: 60px;
  background: transparent;
  color: rgba(255, 255, 255, 0.103);
  border: 1px solid rgba(255, 255, 255, 0.103);
}

.flip-in-hor-bottom {
  animation: flip-in-hor-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.select-form {
  background: #ffffff30;
  border: 0;
  border-radius: 20px;
  padding: 15px;
  height: 45px;
  color: #ffffffc9;
}
@keyframes flip-in-hor-bottom {
  0% {
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

/*Container*/

.kt-login__title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
}

.kt-login__desc {
  margin-top: 1.5rem;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}

/*Formulário*/



.kt-login__btn-primary {
  height: 46px;
  display: inline-block;
  text-align: center;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 0.8rem;
  border-radius: 60px;
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.mt_button_second {
  height: 46px;
  display: inline-block;
  text-align: center;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 0.8rem;
  border-radius: 60px;
  background: transparent;
  color: rgba(255, 255, 255, 0.103);
  border: 1px solid rgba(255, 255, 255, 0.103);
}

.kt-login__actions {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2rem;
}

/*Responsividade abaixo desta linha*/
@media (max-width: 768px) {
  .mt_container {
    padding: 10% 2rem 1rem 2rem;
    width: 90% !important;
  }
  .mt_option {
    padding-top: 8px;
    display: flex;
    justify-content: center;
  }
  .mt_signin {
    margin: 0;
  }
}

.img-device {
  border-radius: 50%;
  margin-left: 10px !important;
  background-size: cover !important;
  cursor: pointer;
}

#page-map {
  width: 100%;
  height: 100vh;
}

form.landing-page-form {
  width: 500px;

  background: #ffffff;
  border: 1px solid #d3e2e5;
  border-radius: 30px;

  padding: 20px 40px;

  position: absolute;
  top: 30px;
  left: 70px;

  z-index: 1;
}

#page-map .leaflet-container {
  z-index: 0;
  border-radius: 20px;
}

form.landing-page-form fieldset {
  border: 0;
}

form.landing-page-form fieldset legend {
  width: 100%;

  font-size: 32px;
  line-height: 34px;
  color: #5c8599;
  font-weight: 700;

  border-bottom: 1px solid #d3e2e5;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

form.landing-page-form .input-block + .input-block {
  margin-top: 24px;
}

form.landing-page-form .input-block label {
  display: flex;
  color: #8fa7b3;
  margin-bottom: 8px;
  line-height: 24px;
}

form.landing-page-form .input-block label span {
  font-size: 14px;
  color: #8fa7b3;
  margin-left: 24px;
  line-height: 24px;
}

form.landing-page-form .input-block input {
  width: 100%;
  background: #f5f8fa;
  border: 1px solid #d3e2e5;
  border-radius: 20px;
  outline: none;
  color: #5c8599;
}

form.landing-page-form .input-block input {
  height: 44px;
  padding: 0 16px;
}

form.landing-page-form button.confirm-button {
  margin-top: 34px;

  width: 100%;
  height: 64px;
  border: 0;
  cursor: pointer;
  background-color: #752525;
  border-radius: 20px;
  color: #ffffff;
  font-weight: 800;

  transition: background-color 0.2s;
}

form.landing-page-form button.confirm-button:hover {
  background-color: #7b7b7b;
}

/* Pop Up - Marker */

#page-map .map-popup .leaflet-popup-content-wrapper {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  box-shadow: none;
  font-family: 'Poppins';
}

#page-map .map-popup .leaflet-popup-content h3 {
  color: #752525;
  font-size: 17px;
  font-weight: bold;
  margin: 8px 12px;
}

.btn-map {
  border-radius: 100px;
  background: #752525 !important;
  border: 0;
}

#page-map .map-popup .leaflet-popup-content p {
  color: #042f38;
  font-size: 12px;
  margin: 8px 12px;
  line-height: 15px;
  margin-bottom: 20px;
}

#page-map .map-popup .leaflet-popup-tip-container {
  display: none;
}

/* Styling react select */

.filter__control {
  border-radius: 20px !important;

  width: 100% !important;
  background: #f5f8fa !important;
  border: 1px solid #d3e2e5 !important;
  border-radius: 20px !important;
  outline: none !important;
  color: #5c8599 !important;
}

.filter__option {
  background: #f5f8fa !important;
  color: #5c8599 !important;
}

.filter__option--is-focused {
  background: #d3e2e5 !important;
  color: #010101 !important;
}

/*Detalhes*/
.box-equipments {
  width: 100%;
  height: 433px;
  border-radius: 15px;
}
.box-equipments-inner {
  width: 100%;
  overflow-y: scroll;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100%;
}
.box-equipments-inner::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #fff;
  height: 100px;
}

.box-equipments-inner::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
  height: 20px;
}

.box-equipments-inner::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #acacac;
}

.box-equipments ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.box-equipments ul li {
  width: 100%;
  height: 64px;
  background: #dfdfdf;
  border-radius: 20px;
  color: #444444;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 500ms;
  margin-bottom: 7px;
  margin-right: 15px;
}
.box-equipments ul li:hover {
  background: #c7c7c7;
}

.box-equipments-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.box-equipment-data {
  width: 100%;
}
.box-equipment-data img {
  width: 20%;
  border-radius: 100%;
}

.title-device {
  margin-top: 15px;
}

.box-equipments .box-equipments-info span {
  font-weight: bold;
  margin-left: 10px;
}

.box-equipments .box-equipments-button {
  padding-right: 20px;
}

.title-equipments {
  font-size: 24px;
  padding-bottom: 15px;
  color: #525151;
}
.title-equipments a {
  color: #525151;
}
.title-equipments a:hover {
  color: #979797;
  transition: 300ms;
}
.title-equipments img {
  margin: 0 5px 0 5px;
}

.load-more {
  background: #fff !important;
}

.loadMore-maps {
  border-radius: 100px;
  padding: 10px 25px;
  background: #752525 !important;
  border: 0;
}

.loadMore-maps:hover {
  background: #4e0e0e !important;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff !important;
  -webkit-box-shadow:  #f7f8fa2b inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
.placeholder-custom::-webkit-input-placeholder { /* Chrome, Safari, Opera */
  color: #ffffff;
  font-style: italic;
}
.mt_logo_sidebar {
  width: 75%;
  margin: 15px 0;
}

.fade-in {
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.slide-in-left {
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/*Keyframes para efeito no logotipo*/
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*Keyframes para efeito no menu*/
@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.p-4 {
  width: 100%;
}

#sidebar {
  min-width: 270px;
  max-width: 300px;
  background: url('/media/bg/bg-menu.webp');
  /*background: #651b1c;*/
  color: #fff;
  transition: all 0.3s;
  position: relative;
  display: flex;
  align-items: start;
  background-attachment: fixed;
}
#sidebar .h6 {
  color: #fff;
}
#sidebar.active {
  display: block;
}
#sidebar.disabled {
  display: none;
}
#sidebar h1 {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 30px;
}
#sidebar h1 .logo {
  color: #fff;
}
#sidebar h1 .logo span {
  font-size: 14px;
  color: #44bef1;
  display: block;
}
#sidebar ul.components {
  padding: 0;
}
#sidebar ul li {
  font-size: 14px;
}
#sidebar ul li > ul {
  margin-left: 10px;
}
#sidebar ul li > ul li {
  font-size: 14px;
}
#sidebar ul li a {
  padding: 10px 0;
  display: block;
  color: rgb(255 255 255 / 75%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
#sidebar ul li a span {
  color: #44bef1;
}
#sidebar ul li a:hover {
  color: #fff;
}
#sidebar ul li a.active {
  background: transparent;
  color: #fff;
}
@media (max-width: 991.98px) {
  #sidebar {
    margin-left: -270px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  .navbar {
    align-items: flex-start !important;
  }
}
#sidebar .custom-menu {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 0;
  margin-right: -20px;
  transition: 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  #sidebar .custom-menu {
    transition: none;
  }
}
#sidebar .custom-menu .btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
}
#sidebar .custom-menu .btn i {
  margin-right: -40px;
  font-size: 14px;
}
#sidebar .custom-menu .btn.btn-primary {
  background: transparent;
  border-color: transparent;
  z-index: 10;
}
#sidebar .custom-menu .btn.btn-primary:after {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  transform: rotate(45deg);
  background: #5c1010;
  border-radius: 10px;
}
#sidebar .custom-menu .btn.btn-primary:hover,
#sidebar .custom-menu .btn.btn-primary:focus {
  background: transparent !important;
  border-color: transparent !important;
}

a[data-toggle='collapse'] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.chooseSystem {
  height: 40px;
}

@media (max-width: 991.98px) {
  #sidebarCollapse span {
    display: none;
  }
}

body {
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 14px;
  line-height: 1.8;
  font-weight: normal;
  background: #eef0f8;
  color: gray;
}

.wrapper {
  width: 100%;
}

.defaultCards{
 
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  transition: all 0.3s;
}

.content_all {
  padding: 2.5em;
}

.barChart {
  padding: 50px;
  margin: 0px 40px 40px 40px;
}

.doughnutChart {
  text-align: center;
  margin-bottom: 25px;
}

.icones-uf {
  display: flex;
  width: 40px;
  height: 40px;
  background: #792b2b;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 10px;
  margin: 0 auto;
}

.table-dashboard {
  text-align: center;
  width: 100%;
}

.height-limit {
  min-height: 355px;
}

.estados-dash .alert-secondary {
  color: #383d41;
  background-color: #f0f0f0;
  border-color: #ffffff;
}
.icon-contract-type-dash {
  width: 40px;
  height: 40px;
  background: #fff5f8;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #b41e1e;
}

.icon-whatsapp-dash {
  width: 40px;
  height: 40px;
  background: #e7fae2;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #189e48;
}

.icon-whatsapp-dash-negative {
  width: 40px;
  height: 40px;
  background: #fff3f3;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #7f1212;
}
.table-contract-type-dash td {
  border-top: 0 !important;
}

.table-contract-type-dash td span {
  width: 100%;
  height: 30px;
  background: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #6e6e6e;
  margin-top: 5px;
}

.card-mt-4 {
  margin-top: 15px;
}

@media (max-width: 768px) {
  .barChart {
    padding: 0;
    margin: 0;
  }
  .doughnutChart {
    margin-top: 40px;
  }
}

.topbar {
  background: #fff;
  padding: 20px 35px 20px 35px;
  z-index: -2;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.08);
}
.btn-topbar {
  width: 35px;
  height: 35px;
  background: #fff;
  text-align: center;
  padding: 5px;
  margin: 0 2px;
  border-radius: 10px;
}
.btn-topbar:hover {
  background: #ffedee;
  cursor: pointer;
  transition: 0.5s;
}
.btn-topbar svg {
  width: 19px;
}
.welcome {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.image-user {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-left: 10px !important;
  background-size: cover !important;
  cursor: pointer;
}

.image-user:hover {
  box-shadow: inset 0 0 0 10000px rgba(0, 0, 0, 0.35);
}

.image-user label {
  display: none;
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 530;
  color: white;
  text-align: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.image-user:hover label {
  display: flex;
}

.dropdown-menu {
  min-width: 18rem;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  border: 0;
  cursor: default;
}
.dropdown-menu a {
  width: 100%;
  display: block;
}
.dropdown-menu.show {
  padding: 0px;
  margin-top: 40px;
  left: -150px !important;
}

.dropdown-dashboard button {
  width: 150px;
  background: #fff;
  color: #585858;
  border: 1px solid #a4a4a4;
}
.dropdown-dashboard .dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 6px !important;
  transform: translateY(-50%);
}
.dropdown-dashboard .dropdown-menu.show {
  padding: 0px;
  margin-top: 40px;
  left: 0px !important;
  top: -35px !important;
}

@media (max-width: 768px) {
  .dropdown-menu {
    min-width: 20rem;
  }
  .dropdown-menu.show {
    padding: 0px;
    margin-top: 40px;
    left: -50px !important;
  }
  .containerNotification {
    padding: 0px;
  }
}

.headerNotification {
  width: 100%;
  height: 5rem;
  background: #5f5f5f;
  color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 7px 7px 0 0;
}
.headerNotification h4 {
  font-size: 15px;
  line-height: 25px;
}

.containerNotification {
  padding: 10px;
}
.containerNotification p {
  font-size: 11px;
  line-height: 17px;
  padding-left: 10px;
}

.iconNotification {
  width: 35px;
  height: 35px;
  background: #fbe1e1;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #5a1213;
  margin-right: 2;
}
.iconNotification:hover {
  width: 38px;
  height: 38px;
  transition: 0.5s;
}

.noNotifications {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.fade-in-top {
  animation: fade-in-top 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-11-11 15:49:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
@keyframes fade-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.lineNotification :hover {
  cursor: pointer;
}

.newNotification {
  font-weight: bold;
}

.oldNotification {
  font-weight: normal;
}

@media (max-width: 768px) {
  .dropdown-menu {
    min-width: 20rem;
  }
  .dropdown-menu.show {
    padding: 0px;
    margin-top: 40px;
    left: -50px !important;
  }
  .containerNotification {
    padding: 0px;
  }
}

.headerNotification {
  width: 100%;
  height: 5rem;
  background: #5f5f5f;
  color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 7px 7px 0 0;
}
.headerNotification h4 {
  font-size: 15px;
  line-height: 25px;
}

.containerNotification {
  padding: 10px;
}
.containerNotification p {
  font-size: 11px;
  line-height: 17px;
  padding-left: 10px;
}

.iconNotification {
  width: 35px;
  height: 35px;
  background: #fbe1e1;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #5a1213;
  margin-right: 2;
}
.iconNotification:hover {
  width: 38px;
  height: 38px;
  transition: 0.5s;
}

.iconNotification2 {
  width: 35px;
  height: 35px;
  background: #fbe1e1;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #5a1213;
  margin-right: 2;
}
.iconNotification2:hover {
  width: 38px;
  height: 38px;
  transition: 0.5s;
}

.noNotifications {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.fade-in-top {
  animation: fade-in-top 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@media (max-width: 768px) {
  .dropdown-menu {
    min-width: 20rem;
  }
  .dropdown-menu.show {
    padding: 0px;
    margin-top: 40px;
    left: -50px !important;
  }
  .containerNotification {
    padding: 0px;
  }
}

.headerNotification {
  width: 100%;
  height: 5rem;
  background: #5f5f5f;
  color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 7px 7px 0 0;
}
.headerNotification h4 {
  font-size: 15px;
  line-height: 25px;
}

.containerNotification {
  padding: 10px;
}
.containerNotification p {
  font-size: 11px;
  line-height: 17px;
  padding-left: 10px;
}

.iconNotification {
  width: 35px;
  height: 35px;
  background: #fbe1e1;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #5a1213;
  margin-right: 2;
}
.iconNotification:hover {
  width: 38px;
  height: 38px;
  transition: 0.5s;
}

.noNotifications {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.fade-in-top {
  animation: fade-in-top 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}
.avatar-customer{
    width: 50px;
    height: 50px;
    background-size: cover !important;
}


.errorMessages{
    color: #ff0000;
    border-bottom: 1px solid #ff0000;
    padding: 2px;
    display: flex;
    justify-content: center;
    font-size: 9px
}

.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}
.btn-remove-item {
    display: block;
    padding: 0 5px 0 5px;
    background: #923434;
    border: 0;
    color: #fff;
    height: 20px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-top: 8px;
}
.labelForm{
    display:flex;
    justify-content: space-between;
}
.errorForm{
    border: 1px solid #ff0000 !important;
    background: #fff8f8 !important;
}
.errorFormLabel{
    color: #ff0000
}

/* Logo Cliente */

.customer-logo{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-left: 10px !important;
    background-size: cover !important;
    cursor: pointer;
}

.customer-logo:hover{
    box-shadow: inset 0 0 0 10000px rgba(0, 0, 0, 0.35);
}

.customer-logo label {
    display: none;
    font-size: 8px;
    text-transform: uppercase;
    font-weight: 530;
    color: white;
    text-align: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.customer-logo:hover label {
    display: flex;
}


.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}


.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}

.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}



.btnPermissionCustom{
    padding: 12px;
    display:flex;
    justify-content: space-between;
}
.optionPermission{
    padding: 5px 0 15px 15px;
}
.titleMenu {
    background: #d7fffd;
    color: #1bc5bd;
    border-radius: 0;
    border-left: 3px solid #1bc5bd;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.lineRow{
    margin: 0
}
.titleMenu h4{
    font-size: 12px;
    margin:0
}
.activeScheme{
    display: flex;
    justify-content: space-between;
    border-radius: 0;
    border-left: 3px solid #3699ff;
    margin: 0 0 8px 0
}
.btnAddModule{
    display:flex;
    padding: 20px 25%;
}
.custom-switch {
    padding-left: 1.25rem !important;
}
.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}
.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}

.sw{
    top: 1.25rem;
    left: 1.25rem;
}


.header-card {
  display: flex;
  justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon {
  background-image: url('/media/search/search-icon.svg') !important;
  background-size: contain;
}
.MuiPaper-elevation4 {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%),
    0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root {
  color: #772a2b !important;
}

.formStep input,
select {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label {
  display: inline-block;
  margin: 0.5rem 0;
  color: #212529;
  text-align: left;
}
.formStep span {
  color: #ff0000;
}
.customerStep .next {
  margin-top: 35px;
}

.customerStep .previous {
  margin-top: 35px;
}
.customerStep h2 {
  color: #212529;
  margin-bottom: 20px;
}
.link_color_datatable {
  color: #696969;
}
.link_color_datatable:hover {
  color: #63191a;
  transition: 0.5s;
}
.btn-remove-item {
  display: block;
  padding: 0 5px 0 5px;
  background: #923434;
  border: 0;
  color: #fff;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-top: 8px;
}
.labelForm {
  display: flex;
  justify-content: space-between;
}
.errorForm {
  border: 1px solid #ff0000 !important;
  background: #fff8f8 !important;
}
.errorFormLabel {
  color: #ff0000;
}

.shake-horizontal{animation:shake-horizontal .8s cubic-bezier(.455,.03,.515,.955) both}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-29 10:0:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */@keyframes shake-horizontal{0%,100%{transform:translateX(0)}10%,30%,50%,70%{transform:translateX(-10px)}20%,40%,60%{transform:translateX(10px)}80%{transform:translateX(8px)}90%{transform:translateX(-8px)}}
.formStep input,
select {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label {
  display: inline-block;
  margin: 0.5rem 0;
  color: #212529;
  text-align: left;
}
.formStep span {
  color: #ff0000;
}
.customerStep .next {
  margin-top: 35px;
}

.customerStep .previous {
  margin-top: 35px;
}
.customerStep h2 {
  color: #212529;
  margin-bottom: 20px;
}
.link_color_datatable {
  color: #696969;
}
.link_color_datatable:hover {
  color: #63191a;
  transition: 0.5s;
}
.btn-remove-item {
  display: block;
  padding: 0 5px 0 5px;
  background: #923434;
  border: 0;
  color: #fff;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-top: 8px;
}
.labelForm {
  display: flex;
  justify-content: space-between;
}
.errorForm {
  border: 1px solid #ff0000 !important;
  background: #fff8f8 !important;
}
.errorFormLabel {
  color: #ff0000;
}

.shake-horizontal {
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-1-29 10:0:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
@keyframes shake-horizontal {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
}

.formStep input,
select {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label {
  display: inline-block;
  margin: 0.5rem 0;
  color: #212529;
  text-align: left;
}
.formStep span {
  color: #ff0000;
}
.customerStep .next {
  margin-top: 35px;
}

.customerStep .previous {
  margin-top: 35px;
}
.customerStep h2 {
  color: #212529;
  margin-bottom: 20px;
}
.link_color_datatable {
  color: #696969;
}
.link_color_datatable:hover {
  color: #63191a;
  transition: 0.5s;
}
.btn-remove-item {
  display: block;
  padding: 0 5px 0 5px;
  background: #923434;
  border: 0;
  color: #fff;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-top: 8px;
}
.labelForm {
  display: flex;
  justify-content: space-between;
}
.errorForm {
  border: 1px solid #ff0000 !important;
  background: #fff8f8 !important;
}
.errorFormLabel {
  color: #ff0000;
}

.shake-horizontal {
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-29 10:0:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
@keyframes shake-horizontal {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
}

.camada {
  z-index: 0 !important;
}

.camada-anterior {
  z-index: -1 !important;
}

.formStep input,
select {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label {
  display: inline-block;
  margin: 0.5rem 0;
  color: #212529;
  text-align: left;
}
.formStep span {
  color: #ff0000;
}
.customerStep .next {
  margin-top: 35px;
}

.customerStep .previous {
  margin-top: 35px;
}
.customerStep h2 {
  color: #212529;
  margin-bottom: 20px;
}
.link_color_datatable {
  color: #696969;
}
.link_color_datatable:hover {
  color: #63191a;
  transition: 0.5s;
}
.btn-remove-item {
  display: block;
  padding: 0 5px 0 5px;
  background: #923434;
  border: 0;
  color: #fff;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-top: 8px;
}
.labelForm {
  display: flex;
  justify-content: space-between;
}
.errorForm {
  border: 1px solid #ff0000 !important;
  background: #fff8f8 !important;
}
.errorFormLabel {
  color: #ff0000;
}

.shake-horizontal{animation:shake-horizontal .8s cubic-bezier(.455,.03,.515,.955) both}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-29 10:0:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */@keyframes shake-horizontal{0%,100%{transform:translateX(0)}10%,30%,50%,70%{transform:translateX(-10px)}20%,40%,60%{transform:translateX(10px)}80%{transform:translateX(8px)}90%{transform:translateX(-8px)}}

.filterAlerts{
  display:flex;
  align-items: center;
}

.filterAlerts a{
  color: #000
}

.filterAlerts input, select, textarea {
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  margin-left: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.header-card {
  display: flex;
  justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon {
  background-image: url('/media/search/search-icon.svg') !important;
  background-size: contain;
}
.MuiPaper-elevation4 {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%),
    0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root {
  color: #772a2b !important;
}

.formStep input,
select {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label {
  display: inline-block;
  margin: 0.5rem 0;
  color: #212529;
  text-align: left;
}
.formStep span {
  color: #ff0000;
}
.customerStep .next {
  margin-top: 35px;
}

.customerStep .previous {
  margin-top: 35px;
}
.customerStep h2 {
  color: #212529;
  margin-bottom: 20px;
}
.link_color_datatable {
  color: #696969;
}
.link_color_datatable:hover {
  color: #63191a;
  transition: 0.5s;
}
.btn-remove-item {
  display: block;
  padding: 0 5px 0 5px;
  background: #923434;
  border: 0;
  color: #fff;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-top: 8px;
}
.labelForm {
  display: flex;
  justify-content: space-between;
}
.errorForm {
  border: 1px solid #ff0000 !important;
  background: #fff8f8 !important;
}
.errorFormLabel {
  color: #ff0000;
}

.shake-horizontal{animation:shake-horizontal .8s cubic-bezier(.455,.03,.515,.955) both}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-29 10:0:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */@keyframes shake-horizontal{0%,100%{transform:translateX(0)}10%,30%,50%,70%{transform:translateX(-10px)}20%,40%,60%{transform:translateX(10px)}80%{transform:translateX(8px)}90%{transform:translateX(-8px)}}

.camada {
  z-index: 0 !important;
}

.camada-anterior {
  z-index: -1 !important;
}

.filterAlerts{
  display:flex;
  align-items: center;
}

.filterAlerts a{
  color: #000
}

.filterAlerts input, select, textarea {
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  margin-left: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.header-card {
  display: flex;
  justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon {
  background-image: url('/media/search/search-icon.svg') !important;
  background-size: contain;
}
.MuiPaper-elevation4 {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%),
    0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root {
  color: #772a2b !important;
}

.formStep input,
select {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label {
  display: inline-block;
  margin: 0.5rem 0;
  color: #212529;
  text-align: left;
}
.formStep span {
  color: #ff0000;
}
.customerStep .next {
  margin-top: 35px;
}

.customerStep .previous {
  margin-top: 35px;
}
.customerStep h2 {
  color: #212529;
  margin-bottom: 20px;
}
.link_color_datatable {
  color: #696969;
}
.link_color_datatable:hover {
  color: #63191a;
  transition: 0.5s;
}
.btn-remove-item {
  display: block;
  padding: 0 5px 0 5px;
  background: #923434;
  border: 0;
  color: #fff;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-top: 8px;
}
.labelForm {
  display: flex;
  justify-content: space-between;
}
.errorForm {
  border: 1px solid #ff0000 !important;
  background: #fff8f8 !important;
}
.errorFormLabel {
  color: #ff0000;
}

.shake-horizontal{animation:shake-horizontal .8s cubic-bezier(.455,.03,.515,.955) both}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-29 10:0:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */@keyframes shake-horizontal{0%,100%{transform:translateX(0)}10%,30%,50%,70%{transform:translateX(-10px)}20%,40%,60%{transform:translateX(10px)}80%{transform:translateX(8px)}90%{transform:translateX(-8px)}}

.filterAlerts{
  display:flex;
  align-items: center;
}

.filterAlerts a{
  color: #000
}

.filterAlerts input, select, textarea {
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  margin-left: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}
.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}


.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}
formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}


.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}

.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}


.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}
.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}


.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}
.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}

.sw{
    top: 1.25rem;
    left: 1.25rem;
}

.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}

.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep {
   padding: 15px;
}

.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}
.drop-options li{
    padding: 0px 0px 0px 0px;
    height: 50px;
    display:flex;
    align-items: center;
}
.drop-options li a{
    height: 50px;
    display:flex;
    align-items: center;
}
.icon-attachment{
    font-size: 19px;
    color: #63191a
}


.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}


.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}
.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}
.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}
.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

FormControlLabel {
    color: #1BC5BD;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep {
    padding: 15px;
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}

.hide{
    display: none;
}

.attrTitle{
    position: relative;
    display: flex;
    top: 10px; 
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}

.horizontalLine{
    width: 99.5%;
    border: 1px solid #f0f0ee;
    border-radius: 5px;
}

.btnAdd{
    position: relative;
    float: right;
    margin-right: 0%;
    color: #ffffff;
    background-color: #1BC5BD;
    border-color: #1BC5BD;
    border: none;
    border-radius: 4px;    
}

.btnRemoveItem {
    display: block;
    float: right;
    padding: 0 5px 0 5px;
    background: #923434;
    border: 0;
    color: #fff;
    height: 20px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-top: 8px;
}

.rowAttr{
    margin-bottom: 10px;
    padding-bottom: 10px;
    background-color: #f0f8ff;
    border-top: outset;
    border-width: 4px;
    border-radius: 10px;   
}

.rowAttr_in{
	   animation: rowAttr_in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.rowAttr_out{
	animation: rowAttr_out 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@keyframes rowAttr_in {
    0% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes rowAttr_out {
    0% {
      transform: scale(1);
        opacity: 1;
    }
    100% {
      transform: scale(0);
        opacity: 1;
    }
}

#figure {
  cursor: pointer;
}

#fig {
  width: 20px;
}

.models-logo {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-left: 10px !important;
  background-size: cover !important;
  cursor: pointer;
}

.models-logo:hover {
  box-shadow: inset 0 0 0 10000px rgba(0, 0, 0, 0.35);
}

.models-logo label {
  display: none;
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 530;
  color: white;
  text-align: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.models-logo:hover label {
  display: flex;
}

.logo-img {
  margin: auto;
  width: 50%;
}

.img-device-model {
  border-radius: 50%;
  background-size: cover !important;
  cursor: pointer;
}

.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}
.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep {
    padding: 15px;
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}

.custom-control label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}

.custom-switch label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}


.maxError{
    font-size: 1.2rem;
}

.switcher{
    left: 1.25rem;
}

#idFamily {
    z-index: 999;
}
.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}

.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}


.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}
.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}


.switch-reports{
    z-index: 0 !important;
}

.custom-switch-div{
        padding-left: 0rem;
        display: flex;
        justify-content: left;
}
.switch-reports {
  z-index: 0 !important;
}

.custom-switch-div {
  padding-left: 0rem;
  display: flex;
  justify-content: left;
}

.switch-reports{
    z-index: 0 !important;
}

.custom-switch-div{
        padding-left: 0rem;
        display: flex;
        justify-content: center;
}
.switch-reports{
    z-index: 0 !important;
}

.custom-switch-div{
        padding-left: 0rem;
        display: flex;
        justify-content: center;
}
.switch-reports{
    z-index: 0 !important;
}

.custom-switch-div{
        padding-left: 0rem;
        display: flex;
        justify-content: left;
}
.switch-reports{
    z-index: 0 !important;
}

.custom-switch-div{
        padding-left: 0rem;
        display: flex;
        justify-content: left;
}
.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}
.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}
.header-card{
  display:flex;
  justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
  background-image: url('/media/search/search-icon.svg') !important;
  background-size: contain;

}
.MuiPaper-elevation4 {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
  color: #772a2b !important;
}
.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}
.avatar-customer{
    width: 50px;
    height: 50px;
    background-size: cover !important;
}
.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}

/* Styling react select */

.filterProposal__control {
  border-radius: 20px !important;
  width: 100% !important;
  background: #f6f6f6 !important;
  border: 0px solid #d3e2e5 !important;
  border-radius: 20px !important;
  outline: none !important;
  color: #5c8599 !important;
  margin-bottom: 15px;
}

.filterProposal__option {
  background: #f6f6f6 !important;
  color: #5c8599 !important;
  height: 34px;
}

.filterProposal__option--is-focused {
  background: #f6f6f6 !important;
  color: #010101 !important;
}



@keyframes hide {
  from { opacity: 1 }
  to   { opacity: 0 }
}

.alert-box {
  animation: hide 2s 2s forwards;
  padding: 15px;
  border: 0;
  border-radius: 60px;
  text-align: center;
  margin-top: 15px;
  margin-right: 10px;
}

.success {
  color: #3c763d;
  background-color: #dff0d898;
}
.drop-bases {
  margin: 0 auto; /* alinha a div no centro */
  position: relative;
  
}

.drop-options{
  overflow:hidden
  
}
.backHeader {
  width: 20px;
  margin-right: 15px;
}



.filterAlerts{
  display:flex;
  align-items: center;
}

.filterAlerts a{
  color: #000
}

.filterAlerts input, select, textarea {
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  margin-left: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.animation {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}

#page-map {
  width: 100%;
  height: 100vh;
}

form.landing-page-form {
  width: 500px;
  background: #ffffff;
  border: 1px solid #d3e2e5;
  border-radius: 30px;
  padding: 20px 40px;
  position: absolute;
  top: 30px;
  left: 70px;
  z-index: 1;
}

@media (max-width: 768px) {
  form.landing-page-form {
    width: 90%;
    top: 0px;
    left: 18px;
  }
}

#page-map .leaflet-container {
  z-index: 0;
  border-radius: 20px;
}

form.landing-page-form fieldset {
  border: 0;
}

form.landing-page-form fieldset legend {
  width: 100%;

  font-size: 32px;
  line-height: 34px;
  color: #5c8599;
  font-weight: 700;

  border-bottom: 1px solid #d3e2e5;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

form.landing-page-form .input-block + .input-block {
  margin-top: 24px;
}

form.landing-page-form .input-block label {
  display: flex;
  color: #8fa7b3;
  margin-bottom: 8px;
  line-height: 24px;
}

form.landing-page-form .input-block label span {
  font-size: 14px;
  color: #8fa7b3;
  margin-left: 24px;
  line-height: 24px;
}

form.landing-page-form .input-block input {
  width: 100%;
  background: #f5f8fa;
  border: 1px solid #d3e2e5;
  border-radius: 20px;
  outline: none;
  color: #5c8599;
}

form.landing-page-form .input-block input {
  height: 44px;
  padding: 0 16px;
}

form.landing-page-form button.confirm-button {
  margin-top: 34px;

  width: 100%;
  height: 64px;
  border: 0;
  cursor: pointer;
  background-color: #752525;
  border-radius: 20px;
  color: #ffffff;
  font-weight: 800;

  transition: background-color 0.2s;
}

form.landing-page-form button.confirm-button:hover {
  background-color: #7b7b7b;
}

/* Pop Up - Marker */

#page-map .map-popup .leaflet-popup-content-wrapper {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  box-shadow: none;
  font-family: 'Poppins';
}

#page-map .map-popup .leaflet-popup-content h3 {
  color: #752525;
  font-size: 17px;
  font-weight: bold;
  margin: 8px 12px;
}

.btn-map {
  border-radius: 100px;
  background: #752525 !important;
  border: 0;
}

#page-map .map-popup .leaflet-popup-content p {
  color: #042f38;
  font-size: 12px;
  margin: 8px 12px;
  line-height: 15px;
  margin-bottom: 20px;
}

#page-map .map-popup .leaflet-popup-tip-container {
  display: none;
}

/* Styling react select */

.filter__control {
  border-radius: 20px !important;

  width: 100% !important;
  background: #f5f8fa !important;
  border: 1px solid #d3e2e5 !important;
  border-radius: 20px !important;
  outline: none !important;
  color: #5c8599 !important;
  margin-bottom: 15px;
}

.filter__option {
  background: #f5f8fa !important;
  color: #5c8599 !important;
}

.filter__option--is-focused {
  background: #d3e2e5 !important;
  color: #010101 !important;
}

.filterMonitoring__control {
  border-radius: 20px !important;

  width: 100% !important;
  background: #f5f8fa !important;
  border: 1px solid #d3e2e5 !important;
  border-radius: 20px !important;
  outline: none !important;
  color: #5c8599 !important;
  margin: 0 5px 15px 0;
}

.filterMonitoring__option {
  background: #f5f8fa !important;
  color: #5c8599 !important;
}

.filterMonitoring__option--is-focused {
  background: #d3e2e5 !important;
  color: #010101 !important;
}

.filterMonit > .css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin-right: 10px;
}

/*Detalhes*/
.box-equipments {
  width: 100%;
  height: 433px;
  border-radius: 15px;
}
.box-equipments-inner {
  width: 100%;
  overflow-y: scroll;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100%;
}
.box-equipments-inner::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #fff;
  height: 100px;
}

.box-equipments-inner::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
  height: 20px;
}

.box-equipments-inner::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #acacac;
}

.box-equipments ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.box-equipments ul li {
  width: 100%;
  height: 64px;
  background: #dfdfdf;
  border-radius: 20px;
  color: #444444;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 500ms;
  margin-bottom: 7px;
  margin-right: 15px;
}
.box-equipments ul li:hover {
  background: #c7c7c7;
}

.box-equipments-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.box-equipment-data {
  width: 100%;
}
.box-equipment-data img {
  width: 20%;
  border-radius: 100%;
}

.title-device {
  margin-top: 15px;
}

.box-equipments .box-equipments-info span {
  font-weight: bold;
  margin-left: 10px;
}

.box-equipments .box-equipments-button {
  padding-right: 20px;
}

.title-equipments {
  font-size: 24px;
  padding-bottom: 15px;
  color: #525151;
}
.title-equipments a {
  color: #525151;
}
.title-equipments a:hover {
  color: #979797;
  transition: 300ms;
}
.title-equipments img {
  margin: 0 5px 0 5px;
}

.load-more {
  background: #fff !important;
}

.loadMore-maps {
  border-radius: 100px;
  padding: 10px 25px;
  background: #752525 !important;
  border: 0;
}

.loadMore-maps:hover {
  background: #4e0e0e !important;
}

.modal-xl {
  max-width: 95% !important;
}

.animation {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}

.input-group > .form-control:not(:first-child){
    text-align: center;
}

.header-card{
    display:flex;
    justify-content: space-between;
}
.data-table-extensions > .data-table-extensions-filter > .icon{
    background-image: url('/media/search/search-icon.svg') !important;
    background-size: contain;

}
.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
}
.MuiSvgIcon-root{
    color: #772a2b !important;
}

.formStep input, select, textarea{
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep textarea{
  height: 100px;
  max-height: 100px;
  min-height: 100px;
}
.switch-reports{
    z-index: 0 !important;
}

.custom-switch-div{
        padding-left: 0rem;
        display: flex;
        justify-content: left;
}

.formStep input, select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formStep label{
    display: inline-block;
    margin: .5rem 0;
    color: #212529;
    text-align: left;
}
.formStep span{
    color: #ff0000
}
.customerStep .next{
    margin-top: 35px;
}

.customerStep .previous{
    margin-top: 35px;
}
.customerStep h2{
    color: #212529;
    margin-bottom: 20px
}
.link_color_datatable{
    color: #696969;
}
.link_color_datatable:hover{
    color: #63191a;
    transition: 0.5s;
}

.fa-whatsapp {
  color: green;
}

.fa-envelope {
  color: #ff331d;
}

.fa-times {
  color: red;
}

.fa-check {
  color: green;
}

#page-map {
  width: 100%;
  height: 100vh;
}

form.landing-page-form {
  width: 500px;
  background: #ffffff;
  border: 1px solid #d3e2e5;
  border-radius: 30px;
  padding: 20px 40px;
  position: absolute;
  top: 30px;
  left: 70px;
  z-index: 1;
}

@media (max-width: 768px) {
  form.landing-page-form {
    width: 90%;
    top: 0px;
    left: 18px;
  }
}

#page-map .leaflet-container {
  z-index: 0;
  border-radius: 20px;
}

form.landing-page-form fieldset {
  border: 0;
}

form.landing-page-form fieldset legend {
  width: 100%;

  font-size: 32px;
  line-height: 34px;
  color: #5c8599;
  font-weight: 700;

  border-bottom: 1px solid #d3e2e5;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

form.landing-page-form .input-block + .input-block {
  margin-top: 24px;
}

form.landing-page-form .input-block label {
  display: flex;
  color: #8fa7b3;
  margin-bottom: 8px;
  line-height: 24px;
}

form.landing-page-form .input-block label span {
  font-size: 14px;
  color: #8fa7b3;
  margin-left: 24px;
  line-height: 24px;
}

form.landing-page-form .input-block input {
  width: 100%;
  background: #f5f8fa;
  border: 1px solid #d3e2e5;
  border-radius: 20px;
  outline: none;
  color: #5c8599;
}

form.landing-page-form .input-block input {
  height: 44px;
  padding: 0 16px;
}

form.landing-page-form button.confirm-button {
  margin-top: 34px;

  width: 100%;
  height: 64px;
  border: 0;
  cursor: pointer;
  background-color: #752525;
  border-radius: 20px;
  color: #ffffff;
  font-weight: 800;

  transition: background-color 0.2s;
}

form.landing-page-form button.confirm-button:hover {
  background-color: #7b7b7b;
}

/* Pop Up - Marker */

#page-map .map-popup .leaflet-popup-content-wrapper {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  box-shadow: none;
  font-family: 'Poppins';
}

#page-map .map-popup .leaflet-popup-content h3 {
  color: #752525;
  font-size: 17px;
  font-weight: bold;
  margin: 8px 12px;
}

.btn-map {
  border-radius: 100px;
  background: #752525 !important;
  border: 0;
}

#page-map .map-popup .leaflet-popup-content p {
  color: #042f38;
  font-size: 12px;
  margin: 8px 12px;
  line-height: 15px;
  margin-bottom: 20px;
}

#page-map .map-popup .leaflet-popup-tip-container {
  display: none;
}

/* Styling react select */

.filter__control {
  border-radius: 20px !important;

  width: 100% !important;
  background: #f5f8fa !important;
  border: 1px solid #d3e2e5 !important;
  border-radius: 20px !important;
  outline: none !important;
  color: #5c8599 !important;
  margin-bottom: 15px;
}

.filter__option {
  background: #f5f8fa !important;
  color: #5c8599 !important;
}

.filter__option--is-focused {
  background: #d3e2e5 !important;
  color: #010101 !important;
}

.filterMonitoring__control {
  border-radius: 20px !important;

  width: 100% !important;
  background: #f5f8fa !important;
  border: 1px solid #d3e2e5 !important;
  border-radius: 20px !important;
  outline: none !important;
  color: #5c8599 !important;
  margin: 0 5px 15px 0;
}

.filterMonitoring__option {
  background: #f5f8fa !important;
  color: #5c8599 !important;
}

.filterMonitoring__option--is-focused {
  background: #d3e2e5 !important;
  color: #010101 !important;
}

.filterMonit > .css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin-right: 10px;
}

/*Detalhes*/
.box-equipments {
  width: 100%;
  height: 433px;
  border-radius: 15px;
}
.box-equipments-inner {
  width: 100%;
  overflow-y: scroll;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100%;
}
.box-equipments-inner::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #fff;
  height: 100px;
}

.box-equipments-inner::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
  height: 20px;
}

.box-equipments-inner::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #acacac;
}

.box-equipments ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.box-equipments ul li {
  width: 100%;
  height: 64px;
  background: #dfdfdf;
  border-radius: 20px;
  color: #444444;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 500ms;
  margin-bottom: 7px;
  margin-right: 15px;
}
.box-equipments ul li:hover {
  background: #c7c7c7;
}

.box-equipments-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.box-equipment-data {
  width: 100%;
}
.box-equipment-data img {
  width: 20%;
  border-radius: 100%;
}

.title-device {
  margin-top: 15px;
}

.box-equipments .box-equipments-info span {
  font-weight: bold;
  margin-left: 10px;
}

.box-equipments .box-equipments-button {
  padding-right: 20px;
}

.title-equipments {
  font-size: 24px;
  padding-bottom: 15px;
  color: #525151;
}
.title-equipments a {
  color: #525151;
}
.title-equipments a:hover {
  color: #979797;
  transition: 300ms;
}
.title-equipments img {
  margin: 0 5px 0 5px;
}

.load-more {
  background: #fff !important;
}

.loadMore-maps {
  border-radius: 100px;
  padding: 10px 25px;
  background: #752525 !important;
  border: 0;
}

.loadMore-maps:hover {
  background: #4e0e0e !important;
}

.modal-xl {
  max-width: 95% !important;
}

